<template>
  <g>
    <svg:style>
      .hiddenHandleContainer {
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }
      .hiddenHandleContainer {
        fill: url(#inner-glow);
      }
    </svg:style>
    <linearGradient id="inner-glow" x1="0" y1="0" x2="100%" y2="0">
      <stop offset="0.01" stop-color="#6d6d6d" />
      <stop offset="0.99" stop-color="#fffbea" />
    </linearGradient>
    <filter id="inner-shadow-glow">
      <feFlood :flood-color="fill1" />
      <feComposite operator="out" in2="SourceGraphic" />
      <feMorphology operator="dilate" radius="4" />
      <feGaussianBlur stdDeviation="3" />
      <feComposite operator="atop" in2="SourceGraphic" />
    </filter>

    <g id="hidden_handle">
      <rect
        class="hiddenHandleContainer"
        :x="handleX1"
        :y="handleY1"
        :style="{ stroke: doorStrokeColor }"
        width="8.34"
        height="222.7"
        filter="url(#inner-shadow-glow)"
      />
    </g>
  </g>
</template>

<script>
export default {
  props: {
    isBlack: {
      type: Boolean,
      default: false
    },
    handleX: {
      type: Number,
      default: 0
    },
    handleY: {
      type: Number,
      default: 0
    },
    viewType: {
      type: Object,
      default: () => ({})
    },
    fill: {
      type: String,
      default: ''
    },
    doorMirrorType: {
      type: String,
      default: ''
    },
    doorStrokeColor: {
      type: String,
      default: '#aeaeae'
    }
  },
  computed: {
    handleX1() {
      if (
        this.doorMirrorType !== 'mirrored' &&
        this.viewType.view_type === 'left'
      ) {
        return this.handleX - 14.49;
      }
      if (
        this.doorMirrorType === 'mirrored' &&
        this.viewType.view_type === 'left'
      ) {
        return this.handleX + 14.19;
      }
      if (
        this.doorMirrorType === 'mirrored' &&
        this.viewType.view_type === 'right'
      ) {
        return this.handleX - 14.49;
      }
      return this.handleX + 14.19;
    },
    handleY1() {
      return this.handleY - 25.5;
    },
    fill1() {
      return this.isBlack ? '#000' : this.fill;
    },
    doorColor() {
      return this.doorSolidColor ? this.doorSolidColor : '#aeaeae';
    }
  }
};
</script>
